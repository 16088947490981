import { ComponentProps } from 'react';
import './customToggle.scss';

type Props = ComponentProps<'input'> & {
  onChange?: (checked: boolean) => void;
};

const CustomToggle = ({
  checked = false,
  disabled = false,
  onChange,
  ...rest
}: Props) => {
  return (
    <label className="switch">
      <input
        {...rest}
        type="checkbox"
        defaultChecked={checked}
        disabled={disabled}
        onChange={(e) =>
          !disabled &&
          checked !== e.target.checked &&
          typeof onChange === 'function' &&
          onChange(e.target.checked)
        }
      />
      <span className="slider round"></span>
    </label>
  );
};

export default CustomToggle;
